import { CHANGE_STATE_IVS, HIGHLIGHTED, COUNTDOWN, ADD_PRODUCT_TO_VIDEO } from './actionTypes';

export const stateIvs = (state) => ({
   type: CHANGE_STATE_IVS,
   state,
});
export const highlightedProduct = (productUrl) => ({
   type: HIGHLIGHTED,
   productUrl,
});
export const finishedCountdown = (finishedCountdown) => ({
   type: COUNTDOWN,
   finishedCountdown,
});
export const addProductInMetaData = (product) => ({
   type: ADD_PRODUCT_TO_VIDEO,
   product,
});
