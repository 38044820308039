/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-rest-params */
/* eslint-disable prefer-spread */
/* eslint-disable no-multi-assign */
/* eslint-disable wrap-iife */
/* eslint-disable no-unused-expressions */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setTrackingReady } from '../../redux';

// hook para descargar la lib de facebook pixel con un retraso de 5s
// para mejorar la perfomance y evitar mucha carga al inicio de la app
export default function useFacebookPixel() {
   const dispatch = useDispatch();

   useEffect(() => {
      // verificamos si no esta cargada la lib de fb pixel
      if (window.fbq === undefined && process.env.NEXT_PUBLIC_FB_PIXEL_ID) {
         // iniciamos el timeout de 5s
         const id = setTimeout(() => {
            // codigo para descargar el script de la lib
            // crea una etiqueta <script> y comienza la descarga
            !(function _(f, b, e, v, n, t, s) {
               if (f.fbq) return;
               n = f.fbq = function __() {
                  n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
               };
               if (!f._fbq) f._fbq = n;
               n.push = n;
               n.loaded = !0;
               n.version = '2.0';
               n.queue = [];
               t = b.createElement(e);
               t.async = !0;
               t.src = v;
               s = b.getElementsByTagName(e)[0];
               s.parentNode.insertBefore(t, s);
            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

            // cuando termina de descargar iniciamos la lib
            window.fbq('init', process.env.NEXT_PUBLIC_FB_PIXEL_ID);

            // indicamos que fb pixel ya esta iniciado
            dispatch(setTrackingReady('pixel'));
         }, 5000);

         // si se produce un cambio de pagina, cancelamos la ejecucion del timeout
         return () => {
            clearTimeout(id);
         };
      }

      return () => {};

      // aplicamos como dependencia el dispatch
      // por las dudas que se produzca un cambio de pagina mientras esta corriendo el timeout
      // y evitar que se utilize una referencia vieja
      // (los cambios de pagina generan un store de redux nuevo)
   }, [dispatch]);
}
