import Modal from 'Modal';
import styles from './index.module.scss';
import Button from 'Button';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { hideChangeModalCart } from '../../redux';
import { IoCartOutline } from 'react-icons/io5';
import Link from 'next/link';
import { useRouter } from 'next/router';

const ChangeModalCart = () => {
   const { showChangeModal } = useSelector((state) => state.cart);
   const router = useRouter();
   if (!showChangeModal?.visible) return null;
   const dispatch = useDispatch();
   const onClose = () => {
      dispatch(hideChangeModalCart());
   };

   return (
      <Modal className={styles.container} onClose={onClose}>
         <div className={styles.info}>
            <Image src="/assets/logos/vaypol-black.svg" width={22} height={22} />
            <p>{showChangeModal?.message}</p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               <Button onClick={onClose} label={'Aceptar'} />
               {router.pathname.includes('checkout') && (
                  <Link absolutePath href="/cart">
                     <a
                        style={{
                           display: 'flex',
                           gap: '10px',
                           justifyContent: 'center',
                           marginTop: '10px',
                           color: 'black',
                        }}
                     >
                        <button
                           style={{
                              background: 'transparent',
                              border: 'none',
                              display: 'flex',
                              alignItems: 'center',
                              gap: '6px',
                              cursor: 'pointer',
                           }}
                           onClick={onClose}
                        >
                           <IoCartOutline color="black" fontSize={25} />
                           <span
                              style={{
                                 marginTop: '5px',
                                 fontSize: '14px',
                                 textDecoration: 'underline',
                              }}
                           >
                              Ir al carrito
                           </span>
                        </button>
                     </a>
                  </Link>
               )}
            </div>
         </div>
      </Modal>
   );
};
export default ChangeModalCart;
