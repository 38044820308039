import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';

NProgress.configure({ minimum: 0.3 });

// hook para escuchar a los eventos de cambio de pagina
// y mostrar la barra de carga arriba
const useProgressIndicator = () => {
   const router = useRouter();

   useEffect(() => {
      router.events.on('routeChangeStart', () => NProgress.start());
      router.events.on('routeChangeComplete', () => NProgress.done());
      router.events.on('routeChangeError', () => NProgress.done());

      return () => {
         router.events.off('routeChangeStart', () => NProgress.start());
         router.events.off('routeChangeComplete', () => NProgress.done());
         router.events.off('routeChangeError', () => NProgress.done());
      };
   }, [router.events]);
};

export default useProgressIndicator;
