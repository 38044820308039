import {
   SET_NOTIFICATION_ERROR,
   SET_NOTIFICATION_INFO,
   SET_NOTIFICATION_SUCCESS,
} from './actionTypes';

const initialState = {
   type: '',
   message: '',
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_NOTIFICATION_INFO:
         return {
            ...state,
            type: 'INFO',
            message: action.message,
         };
      case SET_NOTIFICATION_ERROR:
         return {
            ...state,
            type: 'ERROR',
            message: action.message,
         };
      case SET_NOTIFICATION_SUCCESS:
         return {
            ...state,
            type: 'SUCCESS',
            message: action.message,
         };
      default:
         return state;
   }
};

export default reducer;
