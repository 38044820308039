import { useMemo } from 'react';
import { createReduxEnhancer } from '@sentry/react';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './rootReducer';

let store;
// Redux connection with Sentry
const sentryReduxEnhancer = createReduxEnhancer({
   actionTransformer: (action) => {
      return action.type === '@@INIT' ? null : action;
   },
});
function initStore(initialState) {
   return createStore(
      rootReducer,
      initialState,
      composeWithDevTools(applyMiddleware(thunkMiddleware), sentryReduxEnhancer),
   );
}

export const initializeStore = (preloadedState) => {
   let _store = store ?? initStore(preloadedState);

   // After navigating to a page with an initial Redux state, merge that state
   // with the current state in the store, and create a new store
   if (preloadedState && store) {
      _store = initStore({
         ...store.getState(),
         ...preloadedState,
      });
      // Reset the current store
      store = undefined;
   }

   // For SSG and SSR always create a new store
   if (typeof window === 'undefined') return _store;
   // Create the store once in the client
   if (!store) store = _store;

   return _store;
};

export function useStore(initialState) {
   const _store = useMemo(() => initializeStore(initialState), [initialState]);
   return _store;
}
