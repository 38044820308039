const initialState = {
   dropdowns: [],
   links: [],
};

const reducer = (state = initialState) => {
   return state;
};

export default reducer;
