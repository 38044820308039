import { useState, useEffect } from 'react';
import api from '../../api';
import { finishedCountdown, stateIvs } from '../../redux/ivs/ivsActions';

function useLiveInfo(store) {
   const [live, setLive] = useState({});
   useEffect(() => {
      (async () => {
         const resp = await api.live.getInfo();
         if (!resp.hasError) {
            setLive(resp || {});
            store.dispatch(stateIvs({ hasVideo: resp.live, error: false, viewers: resp.viewers }));
            store.dispatch(finishedCountdown(resp.finishCountdown));
         }
      })();
   }, []);
   return live;
}
export default useLiveInfo;
