import React, { useEffect } from 'react';
import useFacebookPixel from '../../hooks/useFacebookPixel';
import useGoogleAnalytics from '../../hooks/useGoogleAnalytics';
import useGoogleTagManager from '../../hooks/useGoogleTagManager';
import usePageView from '../../hooks/usePageView';
// import Inspectlet from '../../services/inpectlet';

// componente wrapper para aplicar analytics y pixel
export default function EventTracking({ children }) {
   if (process.env.NEXT_PUBLIC_ALLOW_ROBOTS === 'true') {
      // cargamos las lib de facebook pixel y analytics
      useFacebookPixel();
      useGoogleAnalytics();
      useGoogleTagManager();
      // Inspectlet();

      // aplicamos el evento pageview
      usePageView();
   }

   useEffect(() => {});

   return <>{children}</>;
}
