import {
   FETCH_PRODUCT_REQUEST,
   FETCH_PRODUCT_SUCCESS,
   FETCH_PRODUCT_FAILURE,
   SET_COLOR,
   SET_VARIANT,
   SET_QUANTITY,
} from './actionTypes';

const initialState = {
   id: null,
   slug: null,
   product: null,
   colorSelected: null,
   variantSelected: null,
   quantity: 0,
   error: null,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_PRODUCT_REQUEST:
         return {
            ...state,
         };

      case FETCH_PRODUCT_SUCCESS:
         return {
            ...state,

            product: action.product,
            colorSelected: action.colorSelected,
            error: null,
         };

      case FETCH_PRODUCT_FAILURE:
         return {
            items: [],
            error: action.error,
         };

      case SET_COLOR:
         return {
            ...state,
            colorSelected: action.color,
            variantSelected: null,
            quantity: 0,
         };

      case SET_VARIANT:
         return {
            ...state,
            variantSelected: action.variant,
            quantity: 1,
         };

      case SET_QUANTITY:
         return {
            ...state,
            quantity: action.quantity,
         };

      default:
         return state;
   }
};

export default reducer;
