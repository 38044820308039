/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useRef } from 'react';
import cn from 'classnames';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClick from '../../hooks/useOutsideClick';
import { hideModalCart } from '../../redux/index';
import styles from './modal.module.scss';

export default function Modal(props) {
   const modalRef = useRef(null);
   const { title, subtitle, children, headerSolid, onClose, className, modalStyles } = props;
   const dispatch = useDispatch();
   const visible = useSelector((state) => state.cart.showModal);

   const classNamesHeader = cn({
      [styles.header]: true,
      [styles['header--solid']]: headerSolid,
   });

   const classNameContent = cn({
      [styles.modal]: true,
      [className]: !!className,
   });
   useOutsideClick(modalRef, () => {
      if (visible) {
         dispatch(hideModalCart());
      }
   });
   return (
      <div className={styles.container}>
         <div className={styles.backdrop} onClick={onClose} />

         <div className={classNameContent} style={modalStyles}>
            <div className={classNamesHeader}>
               {subtitle && (
                  <div>
                     <span className={styles.title}>{title}</span>
                     {subtitle}
                  </div>
               )}
               <div className={styles.closeButton}>
                  <button className={styles.btnClose} onClick={onClose}>
                     <MdClose size={24} color={modalStyles?.btnColor} />
                  </button>
               </div>
            </div>

            <div className={styles.content}>{children}</div>
         </div>
      </div>
   );
}
