import { combineReducers } from 'redux';
import authReducer from './auth/authReducer';
import bannersReducer from './banners/bannersReducer';
import cartReducer from './cart/cartReducer';
import checkoutReducer from './checkout/checkoutReducer';
import faqsReducer from './faqs/faqsReducer';
import helpReducer from './help/helpReducer';
import ivsReducer from './ivs/ivsReducer';
import landingReducer from './landing/landingReducer';
import mapReducer from './map/mapReducer';
import navbarReducer from './navbar/navbarReducer';
import newsletterReducer from './newsletter/newsletterReducer';
import productDetailReducer from './productDetail/productDetailReducer';
import productsReducer from './products/productsReducer';
import siteReducer from './site/siteReducer';
import themeReducer from './theme/themeReducer';
import toastReducer from './toast/toastReducer';
import trackingReducer from './tracking/trackingReducer';

const rootReducer = combineReducers({
   newsletter: newsletterReducer,
   theme: themeReducer,
   auth: authReducer,
   products: productsReducer,
   toast: toastReducer,
   navbar: navbarReducer,
   productDetail: productDetailReducer,
   cart: cartReducer,
   banners: bannersReducer,
   faqs: faqsReducer,
   help: helpReducer,
   checkout: checkoutReducer,
   tracking: trackingReducer,
   landing: landingReducer,
   site: siteReducer,
   ivs: ivsReducer,
   map: mapReducer,
});

export default rootReducer;
