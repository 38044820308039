import {
   SET_ACTIVE_STEP,
   SET_SHIPPING_TYPE,
   UPDATE_SHIPPING_REQUEST,
   UPDATE_SHIPPING_SUCCESS,
   RESET_SHIPPING_ADDRESS,
   UPDATE_BILLING_REQUEST,
   UPDATE_BILLING_SUCCESS,
   RESET_BILLING_ADDRESS,
   UPDATE_BILLING_REQUEST_SUCCESS,
   SET_PAYMENT,
   SET_PHONE_NUMBER,
   UPDATE_SHIPPING_REQUEST_SUCCESS,
   RESET_PAYMENT,
   RESET_USERS,
   SET_PERSONAL_INFO,
   CHANGE_STEP_TITLE_TRUE,
   CHANGE_STEP_TITLE_FALSE,
   SET_CHECKOUT_REGNOLOGIN,
   SET_CHECKOUT_UNREGISTERED,
   SET_HAS_PREVIOUS_ORDERS,
   SET_LOGGED_USER,
   SET_LOGGED_USER_PREVIOUS,
   SET_EMAIL,
   SET_IS_FROM_CHECKOUT,
   RESET_CHECKOUT_STATES,
} from './actionTypes';

const initialState = {
   activeStep: 'shipping-method',
   shippingAddress: {
      street: null,
      number: null,
      house: null,
      suite: null,
      apple_block: null,
      city: null,
      reference: null,
      zipcode: null,
      state_id: null,
      type: null,
      businessSegment: null, // VAYPOL || CITY
   },
   billingAddress: {
      street: null,
      number: null,
      city: null,
      zipcode: null,
      state_id: null,
      country_id: null,
      reference: null,
      apple_block: null,
      house: null,
      suite: null,
      apartment: null,
      businessSegment: null,
   },
   payment: {
      bin: null,
      error: null,
      cardType: null,
      category: null,
      spsToken: null,
      cardBrand: null,
      installmentId: null,
      installment: null,
      cardNumberLast4: null,
      diviceUniqueIdentifier: null,
      birthDate: null,
   },
   personalInfo: {
      first_name: null,
      last_name: null,
      dni: null,
      phone: null,
      area_code: null,
      phone_type: null,
   },
   loading: false,
   stepTitle: false,
   email: null,
   isFromCheckout: false,
   guestCheckout: false,
   noLogin: false,
   noLoginPrevious: false,
   loggedUser: false,
   loggedUserPrevious: false,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_ACTIVE_STEP:
         return {
            ...state,
            activeStep: action.step,
         };
      case SET_SHIPPING_TYPE:
         return {
            ...state,
            activeStep: 'shipping-info',
            shippingAddress: {
               ...state.shippingAddress,
               type: action.shippingType,
            },
         };
      case SET_PAYMENT:
         return {
            ...state,
            payment: {
               ...state.payment,
               ...action.payload,
            },
         };
      case SET_PHONE_NUMBER:
         return {
            ...state,
            personalInfo: {
               ...state.personalInfo,
               ...action.payload,
            },
         };
      case SET_PERSONAL_INFO:
         return {
            ...state,
            personalInfo: {
               ...state.personalInfo,
               ...action.payload,
            },
         };
      case UPDATE_SHIPPING_REQUEST:
         return {
            ...state,
            loading: true,
         };
      case UPDATE_SHIPPING_REQUEST_SUCCESS:
         return {
            ...state,
            loading: false,
         };
      case UPDATE_SHIPPING_SUCCESS:
         return {
            ...state,
            shippingAddress: action.shippingAddress,
            businessSegment: action.businessSegment,
            loading: false,
            activeStep: 'payment',
         };
      case RESET_SHIPPING_ADDRESS:
         return {
            ...state,
            shippingAddress: initialState.shippingAddress,
         };
      case UPDATE_BILLING_REQUEST:
         return {
            ...state,
            loading: true,
         };
      case UPDATE_BILLING_REQUEST_SUCCESS:
         return {
            ...state,
            loading: false,
         };
      case UPDATE_BILLING_SUCCESS:
         return {
            ...state,
            billingAddress: action.billingAddress,
            businessSegment: action.businessSegment,
            loading: false,
            activeStep: 'payment',
         };
      case RESET_BILLING_ADDRESS:
         return {
            ...state,
            billingAddress: initialState.billingAddress,
         };
      case RESET_PAYMENT:
         return {
            ...state,
            payment: initialState.payment,
         };
      case RESET_USERS:
         return {
            ...state,
            noLogin: initialState.noLogin,
            noLoginPrevious: initialState.noLoginPrevious,
         };
      case CHANGE_STEP_TITLE_TRUE:
         return {
            ...state,
            stepTitle: true,
         };
      case CHANGE_STEP_TITLE_FALSE:
         return {
            ...state,
            stepTitle: false,
         };
      case SET_CHECKOUT_UNREGISTERED:
         return {
            ...state,
            guestCheckout: true,
         };
      case SET_CHECKOUT_REGNOLOGIN:
         return {
            ...state,
            noLogin: true,
         };
      case SET_HAS_PREVIOUS_ORDERS:
         return {
            ...state,
            noLoginPrevious: true,
         };
      case SET_LOGGED_USER:
         return {
            ...state,
            loggedUser: true,
         };
      case SET_LOGGED_USER_PREVIOUS:
         return {
            ...state,
            loggedUserPrevious: true,
         };
      case SET_EMAIL:
         return {
            ...state,
            activeStep: initialState.activeStep,
            shippingAddress: initialState.shippingAddress,
            billingAddress: initialState.billingAddress,
            personalInfo: initialState.personalInfo,
            payment: initialState.payment,
            email: action.payload,
         };
      case SET_IS_FROM_CHECKOUT:
         return {
            ...state,
            isFromCheckout: action.payload,
         };
      case RESET_CHECKOUT_STATES:
         return {
            ...state,
            guestCheckout: action.payload,
            noLogin: action.payload,
            noLoginPrevious: action.payload,
            loggedUser: action.payload,
            loggedUserPrevious: action.payload,
         };
      default:
         return state;
   }
};

export default reducer;
