import {
   REQUEST_LOADING,
   AUTH_SUCCESS,
   AUTH_GUEST,
   EMAIL_VERIFICATION_SUCCESS,
   REQUEST_FAILURE,
   RESTORE_AUTH_STATE,
   CLEAR_ERROR,
   LOGOUT,
   CHECKOUT_LOGOUT,
   UPDATEFINISH,
} from './actionTypes';

const initialState = {
   loading: false,
   isLoggedIn: false,
   finish: false,
   user: null,
   errors: [], // { type: 'email', message: 'Email incorrecto' }
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case REQUEST_LOADING:
         return {
            ...state,
            loading: true,
            errors: [],
         };
      case AUTH_SUCCESS:
         return {
            loading: false,
            isLoggedIn: true,
            user: action.user,
            errors: [],
            finish: state.finish,
         };
      case AUTH_GUEST:
         return {
            loading: false,
            isLoggedIn: false,
            user: action.user,
            errors: [],
            finish: state.finish,
         };
      case EMAIL_VERIFICATION_SUCCESS:
         return {
            ...state,
            loading: false,
            errors: [],
         };
      case REQUEST_FAILURE:
         return {
            ...state,
            loading: false,
            errors: action.errors,
         };
      case RESTORE_AUTH_STATE:
         return {
            ...state,
            isLoggedIn: action.isLoggedIn,
            user: action?.user || state.user,
         };
      case CLEAR_ERROR:
         return {
            ...state,
            errors: [],
         };
      case UPDATEFINISH:
         return { ...state, finish: action.payload };
      case LOGOUT:
         return {
            ...state,
            ...initialState,
            finish: action.payload,
         };
      case CHECKOUT_LOGOUT:
         return {
            ...state,
            isLoggedIn: false,
         };
      default:
         return state;
   }
};

export default reducer;
