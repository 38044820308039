import { UPDATE_MAP } from './actionTypes';

const initialState = {
   map: '',
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case UPDATE_MAP:
         return {
            ...state,
            map: action.payload,
         };

      default:
         return state;
   }
};

export default reducer;
