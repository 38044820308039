import { SET_TRACKING_READY } from './actionTypes';

const initialState = {
   ready: false,
   pixel: false,
   analytics: false,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_TRACKING_READY:
         return {
            ...state,
            ...action.payload,
         };
      default:
         return state;
   }
};

export default reducer;
