import { MdOutlineGppGood } from 'react-icons/md';
import {
   FETCH_CART_REQUEST,
   FETCH_CART_SUCCESS,
   FETCH_CART_FAILURE,
   ADD_TO_CART_REQUEST,
   ADD_TO_CART_SUCCESS,
   ADD_TO_CART_FAILURE,
   SET_QUANTITY_CART_FAILURE,
   SET_QUANTITY_CART_SUCCESS,
   SET_CUPON_CODE_SUCCESS,
   SET_CUPON_CODE_FAILURE,
   REMOVE_ITEM_CART_FAILURE,
   REMOVE_ITEM_CART_SUCCESS,
   SHOW_MODAL_CART,
   HIDE_MODAL_CART,
   ADVANCE_TO_NEXT_REQUEST,
   ADVANCE_TO_NEXT_SUCCESS,
   ADVANCE_TO_NEXT_FAILURE,
   DELETE_CART,
   SHOW_CHANGE_MODAL_CART,
   HIDE_CHANGE_MODAL_CART,
} from './actionTypes';

const toastify = () => import(/* webpackChunkName: "toastify" */ '../../services/toastify');

function checkPromo(action, state) {
   if (action.promotions?.line_items.length !== state.promotions?.line_items.length) {
      action.line_items.forEach((element) => {
         if (!state.line_items.includes(element) && element.promo_description.promotion_name) {
            toastify().then((toast) =>
               toast.success(
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                     <MdOutlineGppGood color="white" size={40} />
                     <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span
                           style={{ fontWeight: 'bold', fontSize: 'larger', marginBottom: '5px' }}
                        >
                           Se agregó artículo promocional
                        </span>
                        <span>
                           {element.name}
                           {/* por la promoción {element.promo_description.promotion_name} */}
                        </span>
                     </div>
                  </div>,
               ),
            );
         }
      });
   }
}
const initialState = {
   token: null,
   line_items: [],
   item_count: 0,
   total: 0,
   showChangeModal: { visible: false, message: '' },
   showModal: false,
   loading: true,
   errors: null,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case FETCH_CART_REQUEST:
         return {
            ...state,
            loading: true,
         };

      case FETCH_CART_SUCCESS:
         if (!localStorage.getItem('user')) {
            localStorage.setItem('cartUser', JSON.stringify({ email: action.payload.email }));
         }
         return {
            ...state,
            ...action.payload,
            loading: false,
            errors: null,
         };

      case FETCH_CART_FAILURE:
         return {
            ...state,
            loading: false,
            errors: action.errors,
         };

      case ADVANCE_TO_NEXT_REQUEST:
         return {
            ...state,
            loading: true,
         };

      case ADVANCE_TO_NEXT_SUCCESS:
         return {
            ...action.payload,
            loading: false,
            errors: null,
         };

      case ADVANCE_TO_NEXT_FAILURE:
         return {
            ...state,
            loading: false,
            errors: action.errors,
         };

      case ADD_TO_CART_REQUEST:
         return {
            ...state,
            loading: true,
         };

      case ADD_TO_CART_SUCCESS:
         checkPromo(action.payload, state);
         return {
            ...action.payload,
            showModal: true,
            loading: false,
            errors: null,
         };

      case ADD_TO_CART_FAILURE:
         return {
            ...state,
            loading: false,
            errors: action.errors,
         };

      case SET_QUANTITY_CART_SUCCESS:
         checkPromo(action.payload, state);
         return {
            ...state,
            ...action.payload,
         };

      case SET_QUANTITY_CART_FAILURE:
         return {
            ...state,
            errors: action.errors,
         };

      case SET_CUPON_CODE_SUCCESS:
         return {
            ...state,
            ...action.payload,
         };

      case SET_CUPON_CODE_FAILURE:
         return {
            ...state,
            errors: action.errors,
         };

      case REMOVE_ITEM_CART_SUCCESS:
         return {
            ...state,
            ...action.payload,
         };

      case REMOVE_ITEM_CART_FAILURE:
         return {
            ...state,
            errors: action.errors,
         };
      case SHOW_CHANGE_MODAL_CART:
         return {
            ...state,
            showChangeModal: { visible: true, message: action.payload },
         };
      case HIDE_CHANGE_MODAL_CART:
         return {
            ...state,
            showChangeModal: { visible: false, message: '' },
         };
      case SHOW_MODAL_CART:
         return {
            ...state,
            showModal: true,
         };

      case HIDE_MODAL_CART:
         return {
            ...state,
            showModal: false,
         };
      case DELETE_CART:
         return {
            ...initialState,
            loading: false,
            item_total: '0',
            email: '',
            number: '',
         };
      default:
         return state;
   }
};

export default reducer;
