import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { setTheme } from '../../redux';
import themes from '../../themes';

function ThemeProvider({ children }) {
   const router = useRouter();
   const dispatch = useDispatch();
   const theme = useSelector((state) => state.theme);
   const site = useSelector((state) => state.site);

   useEffect(() => {
      // if (isGenericPage(router.pathname)) {
      //    dispatch(setTheme('generic'));
      // } else {
      const pathRoot = router.pathname.split('/')[1];
      let micrositio;
      site.micrositios?.forEach((element) => {
         if (element.storeId === pathRoot) micrositio = element.storeId;
      });
      if (micrositio) {
         dispatch(setTheme(micrositio));
      } else {
         dispatch(setTheme(process.env.NEXT_PUBLIC_STORE_ID));
      }
   }, [router.pathname, site]);

   useEffect(() => {
      const newTheme = themes[theme.name];

      Object.keys(newTheme).forEach((key) => {
         const cssKey = `--${key}`;
         const cssValue = newTheme[key];
         document.body.style.setProperty(cssKey, cssValue);
      });
   }, [theme]);

   return children;
}

export default ThemeProvider;
