import { SET_THEME } from './actionTypes';

const initialState = {
   name: process.env.NEXT_PUBLIC_STORE_ID,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_THEME:
         return {
            name: action.name,
         };
      default:
         return state;
   }
};

export default reducer;
