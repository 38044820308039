import { CHANGE_STATE_IVS, HIGHLIGHTED, COUNTDOWN, ADD_PRODUCT_TO_VIDEO } from './actionTypes';

const initialState = {
   hasVideo: false,
   error: false,
   finishedCountdown: false,
   productsInMetaData: [],
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case CHANGE_STATE_IVS:
         return { ...state, ...action.state };
      case COUNTDOWN:
         return { ...state, finishedCountdown: action.finishedCountdown };
      case HIGHLIGHTED:
         return { ...state, highlighted: action.productUrl };
      case ADD_PRODUCT_TO_VIDEO:
         return { ...state, productsInMetaData: [action.product, ...state.productsInMetaData] };

      default:
         return state;
   }
};

export default reducer;
