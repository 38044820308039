// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import { useRouter } from 'next/router';
import { RiLiveLine } from 'react-icons/ri';
import styles from './InLiveSticky.module.scss';

const InLiveSticky = () => {
   const router = useRouter();

   const onClickHandler = () => {
      if (router.pathname !== '/') router.push('/');
      else document.getElementById('video-player').scrollIntoView({ behavior: 'smooth' });
   };
   return (
      <button onClick={onClickHandler} className={styles.sticky}>
         <RiLiveLine fontSize={20} />
         <span>Live Shopping</span>
      </button>
   );
};

export default InLiveSticky;
