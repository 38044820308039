const initialState = {
   home: [],
   genders: [],
   sports: [],
   gallery: {},
};

const reducer = (state = initialState) => {
   return state;
};

export default reducer;
