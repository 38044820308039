const initialState = {
   caption: '¿Querés recibir novedades y promociones de Vaypol.com a tu correo?',
   button_text_color: '#ffd100',
   button_background_color: '#000000',
   background_color: '#ffd100',
   text_color: '#000000',
};

const reducer = (state = initialState) => {
   return state;
};

export default reducer;
