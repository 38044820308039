import { useEffect } from 'react';
import sentry from '../../services/sentry';

export default function useSentry() {
   const ENVIRONMENT = process.env.NEXT_PUBLIC_MODE_ENV;
   const ALLOW_SENTRY = process.env.NEXT_PUBLIC_ALLOW_SENTRY;
   // descargamos Sentry a los 5s
   useEffect(() => {
      const timeout = setTimeout(() => {
         (async () => {
            if (ALLOW_SENTRY === 'true' && ENVIRONMENT !== 'development' && !window.sentry) {
               // Si distinto a desarrollo iniciamos la lib
               window.sentry = true;
               sentry.init({
                  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
                  tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE,
                  environment: ENVIRONMENT,
                  normalizeDepth: process.env.NEXT_PUBLIC_SENTRY_NORMALIZE_DEPTH,
                  beforeBreadcrumb(breadcrumb) {
                     if (breadcrumb.category === 'ui.click') {
                        const element = document.querySelector(breadcrumb.message);
                        const data = {};
                        if (element.id) data.element = element.id;
                        return {
                           ...breadcrumb,
                           data,
                           message: breadcrumb.message,
                        };
                     }
                     return breadcrumb;
                  },
               });

               // obtenemos el user logueado
               let user = localStorage.getItem('user') || localStorage.getItem('cartUser');

               // si esta logueado, lo guardamos en Sentry para identificarlo
               if (user) {
                  user = JSON.parse(user);
                  sentry.setUser({ email: user.email });
               }
            }
         })();
      }, 5000);

      return () => {
         clearTimeout(timeout);
      };
   }, []);
}
