import '../styles/main.scss';
import '../../public/nprogress.css';
import { useEffect } from 'react';
import InLiveSticky from 'ChatIVS/InLiveSticky';
import EventTracking from 'EventTracking';
import Head from 'next/head';
import { Provider } from 'react-redux';
import ThemeProvider from '../domain/ThemeProvider';
import useDeleteTagDuplicate from '../hooks/useDeleteTagDuplicate';
import useLiveInfo from '../hooks/useLiveInfo';
import useProgressIndicator from '../hooks/useProgressIndicator';
import useSentry from '../hooks/useSentry';
import useSmoothScrollPolyfill from '../hooks/useSmoothScrollPolyfill';
import { restoreAuthState, fetchCart } from '../redux';
import { useStore } from '../redux/store';
import patchPreact from '../services/patch-preact';
import ChangeModalCart from 'ChangeModalCart';

// aplicamos el polyfill para el intersection-oberserver
require('intersection-observer');

// aplicamos un fix para las imagenes que no respetan la funcion de lazy-load
patchPreact();

export default function App({ Component, pageProps }) {
   const store = useStore(pageProps.initialReduxState);
   useSmoothScrollPolyfill();
   // iniciamos la lib nprogress
   useProgressIndicator();

   // Borramos las etiquetas duplicadas
   useDeleteTagDuplicate('<meta charset="utf-8">', 10000, 'meta');
   useDeleteTagDuplicate('viewport', 10000, 'meta');
   useDeleteTagDuplicate('canonical', 10000, 'link');
   useSentry();
   const live = useLiveInfo(store);
   useEffect(() => {
      // re-hidratamos el store con lo siguiente:
      // -- auth (si esta logueado o no)
      // -- carrito (se crea uno o se obtiene uno ya creado)
      store.dispatch(restoreAuthState());
      store.dispatch(fetchCart());
   }, []);

   return (
      <Provider store={store}>
         <EventTracking>
            <ThemeProvider>
               <Head>
                  {/* https://github.com/vercel/next.js/blob/master/errors/no-document-viewport-meta.md */}
                  <meta name="viewport" content="initial-scale=1.0, width=device-width" />
               </Head>
               <Component {...pageProps} />
               <ChangeModalCart />
               {live?.live && <InLiveSticky />}
               {/* Container para inyectar luego la lib de toastify */}
               <div id="root-toastify" />
            </ThemeProvider>
         </EventTracking>
      </Provider>
   );
}
