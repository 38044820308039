/**
 * Patch that makes Preact skip noscript contents from "next/image" on the client only
 * https://github.com/preactjs/preact/issues/2797#issuecomment-714508329
 * https://github.com/leerob/leerob.io/pull/339/files
 */
import { options } from 'preact';

const patchPreact = () => {
   const CLIENT = typeof document !== 'undefined';
   const old = options.vnode;

   options.vnode = (vnode) => {
      if (vnode.type === 'noscript' && CLIENT) {
         vnode.props.children = null;
      }
      if (old) old(vnode);
   };
};

export default patchPreact;
