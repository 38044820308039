import {
   PAGE_BACK,
   PAGE_NEXT,
   SET_PAGE,
   SET_ORDER_BY,
   PAGE_MORE_NEXT,
   PAGE_MORE_BACK,
   SET_FILTERS_ACTIVE,
} from './actionTypes';

const initialState = {
   items: [],
   pagination: {
      total: 0,
      current: 1,
   },
   // filtersOptions: {
   //    gender: [],
   //    business: [],
   //    kind: [],
   //    brand: [],
   //    sport: [],
   //    size: [],
   // },
   filtersOptions: null,
   // filtersActive: {
   //    genero: '',
   //    slugs: [],
   //    marca: [],
   //    deporte: [],
   //    talle: [],
   //    landing: null,
   //    last_slug: '',
   // }
   filtersActive: null,
   orderBy: 'menor_precio',
   error: null,
   loading: true,
};

const reducer = (state = initialState, action) => {
   switch (action.type) {
      case SET_ORDER_BY:
         return {
            ...state,
            orderBy: action.orderBy,
         };
      case SET_FILTERS_ACTIVE:
         return {
            ...state,
            filtersActive: action.filtersActive,
            loading: true,
         };
      case PAGE_BACK:
         return {
            ...state,
            pagination: {
               ...state.pagination,
               current: action.current,
            },
         };
      case PAGE_MORE_BACK:
         return {
            ...state,
            pagination: {
               ...state.pagination,
               current: action.current,
            },
         };
      case PAGE_NEXT:
         return {
            ...state,
            pagination: {
               ...state.pagination,
               current: action.current,
            },
         };
      case PAGE_MORE_NEXT:
         return {
            ...state,
            pagination: {
               ...state.pagination,
               current: action.current,
            },
         };
      case SET_PAGE:
         return {
            ...state,
            pagination: {
               ...state.pagination,
               current: action.page,
            },
         };
      default:
         return state;
   }
};

export default reducer;
