const initialState = {
   navbar: {
      image: {},
      dropdowns: [],
   },
   theme: {
      font: '',
      benefits: {
         background_color: '',
         text_color: '',
      },
   },
   image_landing: {
      alt_text: '',
      url: '',
      desktop_image: {
         url: '',
         blurhash: '',
         ext: '',
      },
      mobile_image: {
         url: '',
         blurhash: '',
         ext: '',
      },
   },
   categories: [],
   products: [],
};

const reducer = (state = initialState) => {
   return state;
};

export default reducer;
